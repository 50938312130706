<template>
  <div id="PasswordRecover" class="wrap">
    <div v-if="!hasSend" class="confCodeSend">
      <h1 class="title">メールアドレス認証</h1>
      <div id="sendMail" class="inputWrap">
        <v-form-text
          labelSize="small"
          label="メールアドレス"
          itemStyle="block"
          :errMessage="errors.mail_address||[]"
          v-model="mailAddress"
          maxLength="256"
        >
        </v-form-text>
      </div>
      <b-alert
      class="errorWrap"
      :show="typeof errors.errorMessage !== 'undefined'"
      variant="warning">{{errors.errorMessage}}</b-alert>
      <div class="btnArea">
        <b-button
          class="send"
          :disabled="!mailAddress"
          @click="mailSend"
        >
          送信
        </b-button>
      </div>
    </div>
    <div class="messageWrap" v-if="!hasSend">
      <div class="messageTitle">注意事項</div>
      <div class="message">
        入力いただいたメールアドレス宛に、確認コードをお送りします。<br>
        「seigcsrf@info.sei.co.jp」からのメールを受信できるようにしてから送信してください。
      </div>
    </div>
<!-- 送信完了後の画面 -->
    <div id="resetting" v-if="hasSend && !isEnd">
      <div class="messageWrap">
        <div class="message">入力いただいたメールアドレスに確認コードを送信しました。</div>
        <div class="subMessage">新しいパスワードと確認コードを入力してください。</div>
      </div>
      <div class="inputWrap">
        <span class="guide">
          英大文字・英小文字・数字・記号をそれぞれ1つ以上利用し8文字以上入力してください。
        </span>
        <v-form-text
          label="新しいパスワード"
          labelSize="small"
          type="password"
          itemStyle="block"
          inputClass="w-long"
          :errMessage="errors.password || []"
          v-model="password"
          maxLength="255"
          @check = 'passCheck'
        >
        </v-form-text>
        <v-form-text
          label="新しいパスワードの確認"
          labelSize="small"
          type="password"
          itemStyle="block"
          inputClass="w-long"
          :errMessage="errors.passwordConf || []"
          v-model="passwordConf"
          maxLength="255"
          @check = 'passCheck'
        >
        </v-form-text>
        <v-form-text
          label="確認コード"
          labelSize="small"
          itemStyle="block"
          :errMessage="errors.confCode || []"
          v-model="confCode"
          maxLength="255"
        ></v-form-text>
      </div>
      <b-alert
        class="errorWrap"
        :show="typeof errors.errorMessage !== 'undefined'"
        variant="warning">{{errors.errorMessage}}</b-alert>
      <b-button
        class="register"
        :disabled="hasPassErr"
        @click="save"
      >
        登録
      </b-button>
    </div>
    <div class="end" v-if="hasSend && isEnd">
      <h1>パスワードの再設定が完了しました</h1>
      <b-button
        class="btn fw-6"
        @click="$router.push({ name: backRouteName })"
      >
        ログイン画面へ
      </b-button>
    </div>

  </div>
</template>

<script>
import api from '@/modules/api';

export default {
  name: 'passwordRecover',
  props: {
    isCms: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mailAddress: null,
      hasSend: false,
      hasPassErr: false,
      isEnd: false,
      errors: [],
      passwordConf: null,
      password: null,
      confCode: null,
    };
  },
  methods: {
    async mailSend() {
      this.$store.dispatch('page/onWaiting');
      const params = { mail_address: this.mailAddress };
      const res = await api.send('/api/identify/password/sendMail', params)
        .catch((error) => {
          this.errors = error.response.data.message;
          return false;
        });
      if (res !== false) {
        this.hasSend = true;
      }
      this.$store.dispatch('page/offWaiting');
    },
    async save() {
      this.$store.dispatch('page/onWaiting');
      const params = {
        passwordConf: this.passwordConf,
        password: this.password,
        confCode: this.confCode,
        mailAddress: this.mailAddress,
      };
      const res = await api.send('/api/identify/password/save', params)
        .catch((error) => {
          this.errors = error.response.data.message;
          return false;
        });
      if (res !== false) {
        this.isEnd = true;
      }
      this.$store.dispatch('page/offWaiting');
    },
    passCheck() {
      this.$delete(this.errors, 'passwordConf');
      this.hasPassErr = false;
      if (!this.password || !this.passwordConf) {
        return true;
      }
      if (this.password !== this.passwordConf) {
        this.errors = { passwordConf: ['パスワードの確認とパスワードが一致するよう入力してください。'] };
        this.hasPassErr = true;
        return false;
      }
      return true;
    },
  },
  computed: {
    backRouteName() {
      return this.$route.meta.back;
    },
  },

};
</script>


<style scoped>
  .title{
    margin-top: 112px;
    margin-bottom: 70px;
    color: #333333;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
  }
  .confCodeSend .inputWrap{
    width: 430px;
    margin:0 auto;
    display:block;
  }
/* ---送信ボタン--- */
  .send,
  .register{
    background-color: #122889!important;
    color: #FFFFFF!important;
    font-size: 18px;
    font-weight: 600;
    margin: 10px;
  }
  .btnArea{
    margin-top: 30px;
    text-align: center;
  }
/* ---注意事項--- */
  .confCodeSend + .messageWrap{
    width: 800px;
    height: 160px;
    display: block;
    margin: 90px auto;
    border: solid 1px #6E6E6E;
    box-sizing:content-box;
    text-align: center;
  }
  .confCodeSend + .messageWrap .messageTitle{
    display: block;
    width: 800px;
    height: 50px;
    text-align: center;
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 600;
    background-color:#6E6E6E;
    padding:8px;
  }
  .confCodeSend + .messageWrap .message{
    margin:31px 0;
  }
  #resetting{
    margin-top: 12.8vh;
    text-align: center;
  }
  /* 送信完了後の画面 */
  #resetting .messageWrap{
    text-align: center;
    margin:auto;
  }
  #resetting .message{
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 50px;
  }
  #resetting .subMessage{
    font-size: 18px;
  }
  #resetting .inputWrap{
    width: 430px;
    margin:0 auto;
    margin-top:60px;
    text-align: left;
  }
  #resetting .register{
    width: 400px;
  }
  /*完了画面*/
  .end{
    margin:auto;
    margin-top: 32.8vh;
    text-align: center;
  }
  .end h1{
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 46px;
  }
  span.guide{
    font-size: 13px;
    margin-left: 15px;
    margin-bottom: -11px;
    display: block;
  }
</style>
<style>
  #PasswordRecover .errorWrap{
    width: 400px;
    margin:0 auto;
    margin-bottom: 10px;
  }
  </style>
